<template>
<v-main class="light">
  <v-col justify="center">
    <v-row justify="center" class="col-md-8 pt-0 mt-0">
      <h2>Visual Music in action</h2>
      <p class="text-left">
        Select a shape button below to play one of the demo tracks. You should hear a sound as well as its corresponding image on the screen, shifting in tandem with any change in the audio.
        <br>
        <br>
      </p>
      <p class="text-center">
        In other words, you are <b><em>seeing</em></b> the audio that you're hearing!
      </p>
    </v-row>
    <v-row justify="center">
      <div id="canvas-container">
        <canvas id="canvas" style="width:100%; height: 100%; box-shadow: 0 0 10px 6px #999;"></canvas>
      </div>
    </v-row>
    <v-row justify="center">
      <div class="center-text" id="player-controls">
        <v-btn class="mx-2" fab plain dark color="primary" v-on:click="playPauseTone()" :key="isPaused" v-if="isPaused">
          <v-icon>fas fa-play</v-icon>
        </v-btn>
        <v-btn class="mx-2" fab plain dark color="primary" v-on:click="playPauseTone()" :key="isPaused" v-else>
          <v-icon>fas fa-pause</v-icon>
        </v-btn>
        <v-btn class="mx-2" fab plain dark color="primary" v-on:click="toneStop()">
          <v-icon>fas fa-stop</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row class="mt-4" justify="center">
      <div class="information-div" >
        <span>Select a sample track below:</span>
      </div>
    </v-row>
    <v-row class="mt-4" justify="center">
      <v-btn class="ml-6" v-on:click="doAudioTone(0)"> 
        Cube
      </v-btn>
      <v-btn class="ml-6" v-on:click="doAudioTone(1)"> 
        Torus
      </v-btn>
      <v-btn class="ml-6" v-on:click="doAudioTone(2)"> 
        Alphabet Soup
      </v-btn>
    </v-row>
    <v-row justify="center" class="col-md-8 pt-8">
      <h2>How does it work?</h2>
        <p class="text-left">
        In general, sound is just a moving wave that varies over time. 
        The sound that you hear on this app is in stereo, meaining that there are two channels, left and right, whose wave forms differ slightly. 
        This creates a more realistic 3-D audio experience and is how most audio is recorded.
        <br>
        <br>
        VisualMusic makes sound visual by <b>plotting the sound waves from the right and left speakers against each other</b> on an X and Y axis. 
        <br>
        </p>
        <img v-img src="@/assets/lissajoux_sound.gif"/>
        <p class="text-left">
        To illustrate this, we can look at a theoretical track above. On the top and middle, we have the audio from the left and right channels, varyingover time.
        On the bottom, we have the energy of those waves graphed against each other as they vary. The result is an oval!
        <br>
        <br>
        Basically, this is what two stereo sound waves <b><em>look</em></b> like when combined, a type of harmonic motion called a <a href="https://en.wikipedia.org/wiki/Lissajous_curve">Lissajou Curve</a>.
        With this in mind, tracks can be created with the purpose of presenting a certain image, otherwise known as "Visual Music." 
        From cool shapeshifting, to secret codes, to a fully animated music video, the possibilities are endless. 
        <br>
        <br>
        </p>
    </v-row>
    
    <!-- Snackbar information area-->
    <v-snackbar v-model="snackbar">
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="snackbar = false">
              Close
            </v-btn>
          </template>
      </v-snackbar>
  </v-col>
</v-main>
</template>
<style>
  #canvas {
    border: 0px solid grey;
  }
  #canvas-container {
    width: 40vw;
    height: 40vw;
    position: relative;
  }
  @media (max-width: 480px) {
    #canvas-container {
      width: 90vw;
      height: 90vw;
      position: relative;
    }
  }
  #information-div{
    width:80%;
  }
  #center-text{
    width:100%;
    position: relative;
  }
  img{
    width:100%;
    max-width:700px;
}
</style>

<script>

import api from '@/api';
import * as sdk from '@loopring-web/loopring-sdk'
import PrivateKeyProvider from '@loopring-web/web3-provider'

export default {
  name: 'Plot',
  props: {
    act: [String],
    prov: PrivateKeyProvider,
    chain: sdk.ChainId,
  },
  metamask_cond: 0,
  ethereum_account: 0,

  data() {
    return {
      result: '',
      isAdmin: false,
      // a bad name, but this controls if the fab is play or paused
      isPaused: true,
      snackbar: false,
      snackbarMsg: "",
    };
  },

  methods: {
    checkMetaMask() {
      this.metamask_cond = api.checkMetaMask();
    },
    connectMetaMask() {
      api.connectMetaMask().then((val)=> this.ethereum_account = val);
    },
    createLrc(){
    api.createLrc(this.act[0], this.prov, this.chain)
    },
    doAudioTone(urlIndex){
      api.doAudioTone(urlIndex)
      this.isPaused = false;
    },
    playPauseTone(){
      if(!api.isPlayerLoaded()){
        this.snackbarMsg = "No track loaded. Select a track first."
        this.snackbar = true
      }
      else{
        this.isPaused = !api.toneToggleState();
      }
    },
    toneStop(){
      this.isPaused = true;
      api.toneStop()
    },
    isTonePlaying(){
      api.isTonePlaying()
    },
  },
}

</script>
